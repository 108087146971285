"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPanelDetailsCsv = createPanelDetailsCsv;
exports.default = void 0;

var _forEach = _interopRequireDefault(require("lodash/forEach"));

var _trim = _interopRequireDefault(require("lodash/trim"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _moment = _interopRequireDefault(require("moment"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function createPanelDetailsCsv(data) {
  var dataString = '';
  dataString += "User Name,";
  dataString += "".concat(data.userName, "%0A");
  dataString += "Organization,";
  dataString += "".concat(data.organizationName, "%0A");
  dataString += "Panel Upload Date,";
  dataString += "".concat((0, _moment.default)(data.uploadedDate).format('MM/DD/YYYY hh:mm a'), "%0A");
  dataString += "Missing Persons Panel,";
  dataString += "".concat(data.panelName, "%0A");
  dataString += "Missing Persons File,";
  dataString += "".concat(data.panelFileName, "%0A");
  dataString += "%0A";
  dataString += "First Name,";
  dataString += "Middle Name,";
  dataString += "Last Name,";
  dataString += "DOB,";
  dataString += "Gender,";
  dataString += "Address Line 1,";
  dataString += "Address Line 2,";
  dataString += "City,";
  dataString += "State,";
  dataString += "Zip Code,";
  dataString += "Country Code,";
  dataString += "Phone,";
  dataString += '%0A';
  (0, _forEach.default)(data.missingPersons, function (result) {
    dataString += "".concat((0, _trim.default)(result.firstName), ",");
    dataString += "".concat((0, _trim.default)(result.middleName), ",");
    dataString += "".concat((0, _trim.default)(result.lastName), ",");
    dataString += "".concat((0, _trim.default)(result.dob), ",");
    dataString += "".concat((0, _trim.default)(result.gender), ",");
    dataString += "".concat((0, _trim.default)(result.address1), ",");
    dataString += "".concat((0, _trim.default)(result.address2), ",");
    dataString += "".concat((0, _trim.default)(result.city), ",");
    dataString += "".concat((0, _trim.default)(result.state), ",");
    dataString += "".concat((0, _trim.default)(result.zip), ",");
    dataString += "".concat((0, _trim.default)(result.countryCode), ",");
    dataString += "".concat((0, _trim.default)(result.phone), ",");
    dataString += '%0A';
  });
  var csv = "data:text/csv;charset=UTF-8,".concat(dataString.replaceAll('#', '%23')); // Pound symbol breaks CSV, so encod

  return csv;
}

function createReportCsv(data) {
  var dataString = '';
  dataString += "User Name,";
  dataString += "".concat(data.username, "%0A");
  dataString += "Organization,";
  dataString += "".concat(data.organizationName, "%0A");
  dataString += "Report Created Date,";
  dataString += "".concat((0, _moment.default)(data.reportCreatedDate).format('MM/DD/YYYY h:mm a'), "%0A");
  dataString += "Missing Persons Panel,";
  dataString += "".concat(data.panelName, "%0A");
  dataString += "Missing Persons File,";
  dataString += "".concat(data.panelFileName, "%0A");
  dataString += "Missing Persons Panel Date,";
  dataString += "".concat((0, _moment.default)(data.created).format('MM/DD/YYYY h:mm a'), "%0A");
  dataString += "%0A";
  dataString += "First Name,";
  dataString += "Middle Name,";
  dataString += "Last Name,";
  dataString += "DOB,";
  dataString += "Gender,";
  dataString += "Address Line 1,";
  dataString += "Address Line 2,";
  dataString += "City,";
  dataString += "State,";
  dataString += "Zip Code,";
  dataString += "Country Code,";
  dataString += "Phone,";
  dataString += "Reported On,";
  dataString += "Status,";
  dataString += "Last Seen Facility,";
  dataString += "Last Seen Address,";
  dataString += "Last Seen Contact,";
  dataString += "Last Seen Date,";
  dataString += '%0A';
  (0, _forEach.default)(data.patients, function (patient) {
    dataString += "".concat((0, _trim.default)(patient.firstName), ",");
    dataString += "".concat((0, _trim.default)(patient.middleName), ",");
    dataString += "".concat((0, _trim.default)(patient.lastName), ",");
    dataString += "".concat((0, _trim.default)(patient.dob), ",");
    dataString += "".concat((0, _trim.default)(patient.gender), ",");
    dataString += "".concat((0, _trim.default)(patient.address1), ",");
    dataString += "".concat((0, _trim.default)(patient.address2), ",");
    dataString += "".concat((0, _trim.default)(patient.city), ",");
    dataString += "".concat((0, _trim.default)(patient.state), ",");
    dataString += "".concat((0, _trim.default)(patient.zip), ",");
    dataString += "".concat((0, _trim.default)(patient.countryCode), ",");
    dataString += "".concat((0, _trim.default)(patient.phone), ",");
    dataString += "".concat((0, _moment.default)(patient.created).format('MM/DD/YYYY h:mm a'), ",");
    dataString += "".concat((0, _trim.default)(patient.status), ",");
    dataString += "".concat((0, _trim.default)(patient.facility), ",");
    dataString += "".concat((0, _trim.default)(patient.facilityAddress), ",");
    dataString += "".concat((0, _trim.default)(patient.pointOfContact), ",");
    dataString += "".concat(!(0, _isNil.default)(patient.foundEnsMessage) ? (0, _trim.default)((0, _moment.default)(patient.foundEnsMessage).format('MM/DD/YYYY h:mm a')) : '', ",");
    dataString += '%0A';
  });
  var csv = "data:text/csv;charset=UTF-8,".concat(dataString.replaceAll('#', '%23')); // Pound symbol breaks CSV, so encode it

  return csv;
}

var _default = createReportCsv;
var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(createPanelDetailsCsv, "createPanelDetailsCsv", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/util/create-csv.js");
  reactHotLoader.register(createReportCsv, "createReportCsv", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/util/create-csv.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/util/create-csv.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();