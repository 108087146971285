"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _showNotification = _interopRequireDefault(require("../../../actions/show-notification"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function createReportBefore(context) {
  context.dispatch('CREATE_REPORT_START');
}

function createReportSuccess(context, _ref) {
  var response = _ref.response;
  context.executeAction(_showNotification.default, {
    heading: 'New Report Created Successfully',
    color: 'success'
  });
  context.dispatch('CREATE_REPORT_FINISH', response.data);
}

function createReportFailure(context) {
  context.executeAction(_showNotification.default, {
    heading: 'Create Report Unsuccessful',
    message: 'We are unable to create this report',
    color: 'danger'
  });
  context.dispatch('SUBMIT_PANEL_FAILED');
}

var _default = function _default(config) {
  return {
    serviceName: 'missingPersons.createReport',
    authenticate: true,
    config: {
      method: 'PUT',
      url: (0, _get.default)(config, 'endpoints.missingPersons.createReport')
    },
    beforeRequest: createReportBefore,
    onSuccess: createReportSuccess,
    onError: createReportFailure,
    onFailed: createReportFailure,
    onTimeout: createReportFailure,
    onFatal: createReportFailure
  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(createReportBefore, "createReportBefore", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/create-report/createReport.js");
  reactHotLoader.register(createReportSuccess, "createReportSuccess", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/create-report/createReport.js");
  reactHotLoader.register(createReportFailure, "createReportFailure", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/create-report/createReport.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/create-report/createReport.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();