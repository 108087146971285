"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zipCodePattern = exports.phonePatternJustNumbers = exports.phonePattern = exports.namePattern = exports.countryCodePattern = exports.basicPattern = void 0;

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var namePattern = /^(?!\s)(?![\s\S]*\s$)^[A-Za-z0-9\s\-']*$/;
exports.namePattern = namePattern;
var zipCodePattern = /^([0-9]{5})(-[0-9]{4})?$/;
exports.zipCodePattern = zipCodePattern;
var countryCodePattern = /^\+\d{1,4}$/;
exports.countryCodePattern = countryCodePattern;
var phonePattern = /^\d{3}-\d{3}-\d{4}$/;
exports.phonePattern = phonePattern;
var phonePatternJustNumbers = /^\d{10}$/;
exports.phonePatternJustNumbers = phonePatternJustNumbers;
var basicPattern = /^[^&~^\\]*$/;
exports.basicPattern = basicPattern;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(namePattern, "namePattern", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(zipCodePattern, "zipCodePattern", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(countryCodePattern, "countryCodePattern", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(phonePattern, "phonePattern", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(phonePatternJustNumbers, "phonePatternJustNumbers", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(basicPattern, "basicPattern", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/reg-ex-patterns.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();