"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fluxiblePendo = _interopRequireDefault(require("@audacious/fluxible-pendo"));

var _get = _interopRequireDefault(require("lodash/get"));

var _client = require("@audacious/client");

require("./styles.css");

var _stores = _interopRequireDefault(require("./stores"));

var _services = require("./services");

var _config = require("./config");

var _root = _interopRequireDefault(require("./components/root"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var _default = function _default(config) {
  var app = new _client.Client({
    components: {
      Application: _root.default
    },
    stores: _stores.default,
    context: (0, _config.createApplicationContext)((0, _get.default)(config, 'context', {})),
    services: (0, _services.createServiceConfiguration)((0, _get.default)(config, 'services', {}))
  });
  app.plug(_fluxiblePendo.default);

  function createContext(contextConfiguration) {
    var d = app.createContext((0, _config.createContextConfiguration)(contextConfiguration));
    return d;
  }

  return {
    createContext: createContext,
    getComponent: app.getComponent.bind(app)
  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/index.jsx");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();