"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _name = _interopRequireDefault(require("@audacious/components/lib/formatters/name"));

var _gender = _interopRequireDefault(require("@audacious/components/lib/formatters/gender"));

var _phone = _interopRequireDefault(require("@audacious/components/lib/formatters/phone"));

var _address = _interopRequireDefault(require("@audacious/components/lib/formatters/address"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function formatPatient(patient) {
  var dob = patient.dob,
      phone = patient.phone,
      countryCode = patient.countryCode,
      gender = patient.gender,
      addressLine1 = patient.addressLine1,
      addressLine2 = patient.addressLine2,
      city = patient.city,
      state = patient.state,
      zipCode = patient.zipCode,
      checkinTime = patient.checkinTime,
      checkoutTime = patient.checkoutTime,
      checkoutType = patient.checkoutType,
      checkoutLocation = patient.checkoutLocation,
      dateOfDeath = patient.dateOfDeath;
  var cityState = null;

  if (city && state) {
    cityState = "".concat(city, ", ").concat(state);
  } else if (city) {
    cityState = city;
  } else if (state) {
    cityState = state;
  }

  if (!(0, _isNil.default)(zipCode) && zipCode.length > 0) {
    if (!(0, _isNil.default)(cityState)) {
      cityState = "".concat(cityState, " ").concat(zipCode);
    } else {
      cityState = zipCode;
    }
  }

  return {
    name: (0, _name.default)('fml', patient),
    nameLastFirst: (0, _name.default)('lfm', patient),
    fullAddress: (0, _address.default)({
      streets: [addressLine1, addressLine2],
      city: city,
      state: state,
      postalCode: zipCode
    }),
    address: {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      zipCode: zipCode
    },
    cityState: !(0, _isNil.default)(cityState) ? cityState : '-',
    dob: (0, _moment.default)(dob).format('MM/DD/YYYY'),
    checkInDate: !(0, _isNil.default)(checkinTime) && checkinTime.length > 0 ? (0, _moment.default)(checkinTime).format('MM/DD/YYYY') : null,
    checkOutDate: !(0, _isNil.default)(checkoutTime) && checkoutTime.length > 0 ? (0, _moment.default)(checkoutTime).format('MM/DD/YYYY') : null,
    dateOfDeath: !(0, _isNil.default)(dateOfDeath) && dateOfDeath.length > 0 ? (0, _moment.default)(dateOfDeath).format('MM/DD/YYYY') : null,
    checkOutType: checkoutType,
    checkOutLocation: checkoutLocation,
    phone: (0, _phone.default)({
      number: phone,
      countryCode: countryCode
    }),
    gender: (0, _gender.default)(gender)
  };
}

var _default = formatPatient;
var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(formatPatient, "formatPatient", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/util/format-patient.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/util/format-patient.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();