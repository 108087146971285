"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MIN_DATE = exports.MAX_DATE = void 0;

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var MIN_DATE = new Date('1900/01/01');
exports.MIN_DATE = MIN_DATE;
var MAX_DATE = new Date();
exports.MAX_DATE = MAX_DATE;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(MIN_DATE, "MIN_DATE", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/dates.js");
  reactHotLoader.register(MAX_DATE, "MAX_DATE", "/builds/pulse-platform/pulse-missingperson-web-application/src/constants/dates.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();