"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function beforeGetReports(context) {
  context.dispatch('GET_REPORTS_START');
}

function getReportsSuccess(context, _ref) {
  var response = _ref.response;
  context.dispatch('GET_REPORTS_FINISH', response.data);
}

function getReportsFailure(context) {
  context.dispatch('GET_REPORTS_FAILED');
}

var _default = function _default(config) {
  return {
    serviceName: 'missingPersons.getAllReports',
    authenticate: true,
    config: {
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.missingPersons.getAllReports')
    },
    beforeRequest: beforeGetReports,
    onSuccess: getReportsSuccess,
    onError: getReportsFailure,
    onFailed: getReportsFailure,
    onTimeout: getReportsFailure,
    onFatal: getReportsFailure
  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(beforeGetReports, "beforeGetReports", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-all-reports/getAllReports.js");
  reactHotLoader.register(getReportsSuccess, "getReportsSuccess", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-all-reports/getAllReports.js");
  reactHotLoader.register(getReportsFailure, "getReportsFailure", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-all-reports/getAllReports.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-all-reports/getAllReports.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();