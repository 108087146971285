"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _merge = _interopRequireDefault(require("lodash/merge"));

var _uploadPanel = _interopRequireDefault(require("./upload-panel/uploadPanel"));

var _getAllPanels = _interopRequireDefault(require("./get-all-panels/getAllPanels"));

var _getReport = _interopRequireDefault(require("./get-report/getReport"));

var _createReport = _interopRequireDefault(require("./create-report/createReport"));

var _getAllReports = _interopRequireDefault(require("./get-all-reports/getAllReports"));

var _getPanelDetails = _interopRequireDefault(require("./get-panel-details/getPanelDetails"));

var _endpoints = _interopRequireDefault(require("../configs/endpoints"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var DEFAULT_CONFIG = {
  endpoints: _endpoints.default
};

var _default = function _default(config) {
  var configuration = (0, _merge.default)(DEFAULT_CONFIG, config); // eslint-disable-line

  return [(0, _uploadPanel.default)(configuration), (0, _getAllPanels.default)(configuration), (0, _getReport.default)(configuration), (0, _createReport.default)(configuration), (0, _getAllReports.default)(configuration), (0, _getPanelDetails.default)(configuration)];
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(DEFAULT_CONFIG, "DEFAULT_CONFIG", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/index.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();