"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var patientPropType = _propTypes.default.shape({
  issues: _propTypes.default.arrayOf(_propTypes.default.shape({
    path: _propTypes.default.string,
    messages: _propTypes.default.arrayOf(_propTypes.default.shape({
      text: _propTypes.default.string
    }))
  })),
  patient: _propTypes.default.shape({
    firstName: _propTypes.default.string,
    middleName: _propTypes.default.string,
    lastName: _propTypes.default.string,
    dob: _propTypes.default.shape({
      day: _propTypes.default.number,
      month: _propTypes.default.number,
      year: _propTypes.default.number
    }),
    gender: _propTypes.default.string,
    address: _propTypes.default.shape({
      addressLine1: _propTypes.default.string,
      addressLine2: _propTypes.default.string,
      city: _propTypes.default.string,
      state: _propTypes.default.string,
      zipCode: _propTypes.default.string
    }),
    zipCode: _propTypes.default.string,
    countryCode: _propTypes.default.string,
    phone: _propTypes.default.string,
    needsMedicalAttention: _propTypes.default.bool
  }),
  formatted: _propTypes.default.shape({
    name: _propTypes.default.string,
    address: _propTypes.default.shape({
      addressLine1: _propTypes.default.string,
      addressLine2: _propTypes.default.string,
      city: _propTypes.default.string,
      state: _propTypes.default.string,
      zipCode: _propTypes.default.string
    }),
    dob: _propTypes.default.string,
    phone: _propTypes.default.string,
    gender: _propTypes.default.string
  })
});

var _default = patientPropType;
var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(patientPropType, "patientPropType", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/prop-types/patient-prop-type.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/common/prop-types/patient-prop-type.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();