"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.iterator");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

require("core-js/modules/es.string.iterator");

require("core-js/modules/es.string.starts-with");

require("core-js/modules/web.dom-collections.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelReviewPanel = cancelReviewPanel;
exports.clearImportPanelError = clearImportPanelError;
exports.importPanelFiles = importPanelFiles;
exports.removeErrorPanelItems = removeErrorPanelItems;

var _bluebird = _interopRequireDefault(require("bluebird"));

var _forEach = _interopRequireDefault(require("lodash/forEach"));

var _endsWith = _interopRequireDefault(require("lodash/endsWith"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _toLower = _interopRequireDefault(require("lodash/toLower"));

var _toUpper = _interopRequireDefault(require("lodash/toUpper"));

var _trim = _interopRequireDefault(require("lodash/trim"));

var _findIndex = _interopRequireDefault(require("lodash/findIndex"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _reduce = _interopRequireDefault(require("lodash/reduce"));

var _map = _interopRequireDefault(require("lodash/map"));

var _papaparse = _interopRequireDefault(require("papaparse"));

var _usaStates = _interopRequireDefault(require("usa-states"));

var _gender = _interopRequireDefault(require("@audacious/components/lib/formatters/gender"));

var _phone = _interopRequireDefault(require("@audacious/components/lib/formatters/phone"));

var _validators = require("@audacious/components/lib/validators");

var _regExPatterns = require("../constants/reg-ex-patterns");

var _dates = require("../constants/dates");

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var provinces = new _usaStates.default.UsaStates({
  includeTerritories: true
});
var provinceLookup = (0, _reduce.default)(provinces.states, function (acc, state) {
  acc[state.abbreviation] = state;
  return acc;
}, {});

function stateValidator(value) {
  if ((0, _isNil.default)(value)) {
    return null;
  }

  var province = provinceLookup[value];

  if (!(0, _isNil.default)(province)) {
    return null;
  }

  return 'Invalid State';
}

function genderValidator(value) {
  var results = (0, _gender.default)(value);

  if ((0, _isEmpty.default)(results)) {
    return 'Invalid Gender Value';
  }

  return null;
}

var INCOMING_LINE_INDEXES = {
  firstName: 0,
  middleName: 1,
  lastName: 2,
  dob: 3,
  gender: 4,
  addressLine1: 5,
  addressLine2: 6,
  city: 7,
  state: 8,
  zipCode: 9,
  countryCode: 10,
  phone: 11
};
var headerLocations = ['first name', 'middle name', 'last name', 'dob', 'gender', 'address line 1', 'address line 2', 'city', 'state', 'zip code', 'country code', 'phone'];

function validateColumnHeaders(columnHeaders) {
  (0, _forEach.default)(columnHeaders, function (columnHeader, index) {
    if ((0, _toLower.default)(columnHeader) !== headerLocations[index]) {
      throw new Error("Invalid Column \"".concat(columnHeader, "\""));
    }
  });
}

function pushIfNotNil(array, result) {
  if (!(0, _isNil.default)(result)) {
    array.push(result);
  }
}

function findIndexByElement(issues, path) {
  return (0, _findIndex.default)(issues, function (issue) {
    return issue.path === path;
  });
}

function validateFirstName(firstName) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(firstName));
  pushIfNotNil(messages, (0, _validators.minLengthValidator)(firstName, 2));
  pushIfNotNil(messages, (0, _validators.maxLengthValidator)(firstName, 50));
  pushIfNotNil(messages, (0, _validators.patternValidator)(firstName, _regExPatterns.namePattern));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'First Name',
    path: 'firstName',
    messages: messages
  };
}

function validateMiddleName(middleName) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.maxLengthValidator)(middleName, 50));
  pushIfNotNil(messages, (0, _validators.patternValidator)(middleName, _regExPatterns.namePattern));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'Middle Name',
    path: 'middleName',
    messages: messages
  };
}

function validateLastName(lastName) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(lastName));
  pushIfNotNil(messages, (0, _validators.minLengthValidator)(lastName, 2));
  pushIfNotNil(messages, (0, _validators.maxLengthValidator)(lastName, 50));
  pushIfNotNil(messages, (0, _validators.patternValidator)(lastName, _regExPatterns.namePattern));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'Last Name',
    path: 'lastName',
    messages: messages
  };
}

function validateDob(dob) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(dob));
  pushIfNotNil(messages, (0, _validators.dateValidator)(dob));
  pushIfNotNil(messages, (0, _validators.minDateValidator)(dob, _dates.MIN_DATE));
  pushIfNotNil(messages, (0, _validators.maxDateValidator)(dob, _dates.MAX_DATE));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'DOB',
    path: 'dob',
    messages: messages
  };
}

function validateGender(gender) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(gender)); // just show required if gender is missing

  if (messages.length > 0) {
    return {
      label: 'Gender',
      path: 'gender',
      messages: messages
    };
  }

  pushIfNotNil(messages, genderValidator(gender));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'Gender',
    path: 'gender',
    messages: messages
  };
}

function addressLine(address, lineNumber) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.minLengthValidator)(address, 2));
  pushIfNotNil(messages, (0, _validators.maxLengthValidator)(address, 255));
  pushIfNotNil(messages, (0, _validators.patternValidator)(address, _regExPatterns.basicPattern));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: "Address Line ".concat(lineNumber),
    path: "address.addressLine".concat(lineNumber),
    messages: messages
  };
}

function validateCity(city) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.minLengthValidator)(city, 2));
  pushIfNotNil(messages, (0, _validators.maxLengthValidator)(city, 35));
  pushIfNotNil(messages, (0, _validators.patternValidator)(city, _regExPatterns.basicPattern));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'City',
    path: 'address.city',
    messages: messages
  };
}

function validateState(state) {
  var messages = [];
  pushIfNotNil(messages, stateValidator(state));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'State',
    path: 'address.state',
    messages: messages
  };
}

function validateZipCode(zipCode) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(zipCode));
  pushIfNotNil(messages, (0, _validators.patternValidator)(zipCode, _regExPatterns.zipCodePattern, 'Invalid format'));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'Zip Code',
    path: 'address.zipCode',
    messages: messages
  };
}

function validateCountryCode(countryCode) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(countryCode));
  pushIfNotNil(messages, (0, _validators.patternValidator)(countryCode, _regExPatterns.countryCodePattern, 'The country code is invalid. Please enter a valid country code.'));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'Country Code',
    path: 'countryCode',
    messages: messages
  };
}

function validatePhone(phone) {
  var messages = [];
  pushIfNotNil(messages, (0, _validators.requiredValidator)(phone));
  pushIfNotNil(messages, (0, _validators.patternValidator)(phone, _regExPatterns.phonePattern, 'The phone number is invalid. Please enter a ten-digit phone number.'));

  if (messages.length <= 0) {
    return null;
  }

  return {
    label: 'Phone',
    path: 'phone',
    messages: messages
  };
}

function parseString(value, capitalize) {
  if ((0, _isNil.default)(value) || value.length <= 0) {
    return null;
  }

  if (capitalize) {
    return (0, _toUpper.default)(value);
  }

  return value;
}

function parseDob(value) {
  if ((0, _isNil.default)(value) || value.length <= 0) {
    return null;
  }

  return new Date(value);
}

function convertToItem(line) {
  var issues = [];
  var lineMessages = [];

  if (line.length < headerLocations.length) {
    lineMessages.push('Too few columns');
  }

  if (line.length > headerLocations.length) {
    lineMessages.push('Too many columns');
  }

  if (lineMessages.length > 0) {
    issues.push({
      path: '',
      label: 'General',
      messages: lineMessages
    });
  }

  var firstName = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.firstName]));
  var middleName = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.middleName]));
  var lastName = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.lastName]));
  var unalteredDob = (0, _trim.default)(line[INCOMING_LINE_INDEXES.dob]);
  var dob = parseDob(unalteredDob);
  var gender = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.gender]));
  var addressLine1 = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.addressLine1]));
  var addressLine2 = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.addressLine2]));
  var city = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.city]));
  var state = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.state]), true);
  var zipCode = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.zipCode]));
  var countryCode = parseString((0, _trim.default)(line[INCOMING_LINE_INDEXES.countryCode]));
  var unalteredCountryCode = countryCode;
  var unalteredPhone = (0, _trim.default)(line[INCOMING_LINE_INDEXES.phone]);
  var phone = (0, _phone.default)({
    number: unalteredPhone
  });

  if ((0, _isEmpty.default)(firstName) && (0, _isEmpty.default)(middleName) && (0, _isEmpty.default)(lastName) && (0, _isEmpty.default)(dob) && (0, _isEmpty.default)(gender) && (0, _isEmpty.default)(addressLine1) && (0, _isEmpty.default)(addressLine2) && (0, _isEmpty.default)(city) && (0, _isEmpty.default)(state) && (0, _isEmpty.default)(zipCode) && (0, _isEmpty.default)(countryCode) && (0, _isEmpty.default)(phone)) {
    return null;
  }

  if ((0, _isNil.default)(countryCode)) {
    countryCode = '+1';
  }

  if (!countryCode.startsWith('+')) {
    countryCode = "+".concat(countryCode);
  }

  pushIfNotNil(issues, validateFirstName(firstName));
  pushIfNotNil(issues, validateMiddleName(middleName));
  pushIfNotNil(issues, validateLastName(lastName));
  pushIfNotNil(issues, validateDob(dob));
  pushIfNotNil(issues, validateGender(gender));
  pushIfNotNil(issues, addressLine(addressLine1, 1));
  pushIfNotNil(issues, addressLine(addressLine2, 2));
  pushIfNotNil(issues, validateCity(city));
  pushIfNotNil(issues, validateState(state));
  pushIfNotNil(issues, validateZipCode(zipCode));
  pushIfNotNil(issues, validateCountryCode(countryCode));
  pushIfNotNil(issues, validatePhone(phone));
  var unalteredZipCode = zipCode;

  if (findIndexByElement(issues, 'dob') >= 0) {
    dob = '';
  }

  if (findIndexByElement(issues, 'address.zipCode') >= 0) {
    zipCode = '';
  }

  if (findIndexByElement(issues, 'countryCode') >= 0) {
    countryCode = '';
  }

  if (findIndexByElement(issues, 'phone') >= 0) {
    phone = '';
  }

  return {
    issues: issues,
    patient: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      dob: dob,
      gender: gender,
      address1: addressLine1,
      address2: addressLine2,
      city: city,
      state: state,
      zip: zipCode,
      countryCode: countryCode,
      phone: phone,
      unalteredDob: unalteredDob,
      unalteredZipCode: unalteredZipCode,
      unalteredCountryCode: unalteredCountryCode,
      unalteredPhone: unalteredPhone
    }
  };
}

function importPanelFile(file) {
  if (!(0, _endsWith.default)(file.name, '.csv')) {
    throw new Error('Invalid file type');
  }

  return _bluebird.default.fromCallback(function (callback) {
    _papaparse.default.parse(file, {
      complete: function complete(result) {
        var errors = result.errors,
            data = result.data;
        var executeError = null;

        if (!(0, _isNil.default)(errors) && errors.length > 0) {
          (0, _forEach.default)(errors, function (error) {
            return console.error(error.message);
          });
          executeError = new Error('Selected File(s) are invalid');
        }

        callback(executeError, data);
      }
    });
  }).then(function (lines) {
    var items = [];
    validateColumnHeaders(lines[0]);

    for (var counter = 1; counter < lines.length; counter += 1) {
      var line = lines[counter];
      var item = convertToItem(line);

      if (!(0, _isNil.default)(item)) {
        items.push(item);
      }
    }

    return items;
  });
}

function importPanelFiles(context, files) {
  context.dispatch('IMPORT_PANEL_START');
  var fileName = files[0].name;
  context.dispatch('SET_IMPORT_PANEL_FILE_NAME', fileName);
  return _bluebird.default.delay(100).then(function () {
    var proms = (0, _map.default)(files, function (file) {
      return importPanelFile(file);
    });
    return _bluebird.default.all(proms);
  }).then(function (fileItems) {
    return (0, _reduce.default)(fileItems, function (acc, items) {
      return acc.concat(items);
    }, []);
  }).then(function (items) {
    context.dispatch('IMPORT_PANEL_FINISH', items);
  }).catch(function (error) {
    context.dispatch('IMPORT_PANEL_FAILED', error);
  });
}

function cancelReviewPanel(context) {
  context.dispatch('EXIT_REVIEW_PANEL');
}

function removeErrorPanelItems(context) {
  context.dispatch('REMOVE_ERROR_PANEL_ITEMS');
}

function clearImportPanelError(context) {
  context.dispatch('CLEAR_IMPORT_PANEL_ERROR');
}

;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(provinces, "provinces", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(provinceLookup, "provinceLookup", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(stateValidator, "stateValidator", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(genderValidator, "genderValidator", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(INCOMING_LINE_INDEXES, "INCOMING_LINE_INDEXES", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(headerLocations, "headerLocations", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateColumnHeaders, "validateColumnHeaders", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(pushIfNotNil, "pushIfNotNil", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(findIndexByElement, "findIndexByElement", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateFirstName, "validateFirstName", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateMiddleName, "validateMiddleName", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateLastName, "validateLastName", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateDob, "validateDob", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateGender, "validateGender", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(addressLine, "addressLine", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateCity, "validateCity", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateState, "validateState", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateZipCode, "validateZipCode", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validateCountryCode, "validateCountryCode", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(validatePhone, "validatePhone", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(parseString, "parseString", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(parseDob, "parseDob", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(convertToItem, "convertToItem", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(importPanelFile, "importPanelFile", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(importPanelFiles, "importPanelFiles", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(cancelReviewPanel, "cancelReviewPanel", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(removeErrorPanelItems, "removeErrorPanelItems", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
  reactHotLoader.register(clearImportPanelError, "clearImportPanelError", "/builds/pulse-platform/pulse-missingperson-web-application/src/actions/uploadPanelActions.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();