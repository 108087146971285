"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _showNotification = _interopRequireDefault(require("../../../actions/show-notification"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function beforeUploadPanel(context) {
  context.dispatch('CREATE_REPORT_START');
  context.dispatch('UPLOAD_PANEL_START');
}

function uploadPanelSuccess(context, _ref) {
  var response = _ref.response;
  context.executeAction(_showNotification.default, {
    heading: 'New Panel Uploaded Successfully',
    color: 'success'
  });
  context.dispatch('UPLOAD_PANEL_FINISH', response.data);
  context.dispatch('EXIT_REVIEW_PANEL');
}

function uploadPanelFailure(context) {
  context.executeAction(_showNotification.default, {
    heading: 'Panel Upload Unsuccessful',
    message: 'We are unable to complete your new panel upload',
    color: 'danger'
  });
  context.dispatch('SUBMIT_PANEL_FAILED');
}

var _default = function _default(config) {
  return {
    serviceName: 'missingPersons.uploadPanel',
    authenticate: true,
    config: {
      method: 'POST',
      url: (0, _get.default)(config, 'endpoints.missingPersons.uploadPanel')
    },
    beforeRequest: beforeUploadPanel,
    onSuccess: uploadPanelSuccess,
    onError: uploadPanelFailure,
    onFailed: uploadPanelFailure,
    onTimeout: uploadPanelFailure,
    onFatal: uploadPanelFailure
  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(beforeUploadPanel, "beforeUploadPanel", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/upload-panel/uploadPanel.js");
  reactHotLoader.register(uploadPanelSuccess, "uploadPanelSuccess", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/upload-panel/uploadPanel.js");
  reactHotLoader.register(uploadPanelFailure, "uploadPanelFailure", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/upload-panel/uploadPanel.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/upload-panel/uploadPanel.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();