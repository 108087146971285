"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _isNil = _interopRequireDefault(require("lodash/isNil"));

var _get = _interopRequireDefault(require("lodash/get"));

var _showNotification = _interopRequireDefault(require("../../../actions/show-notification"));

var _createCsv = require("../../../common/util/create-csv");

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function getPanelDetailsSuccess(context, _ref) {
  var response = _ref.response;
  context.executeAction(_showNotification.default, {
    heading: 'Panel Downloaded Successfully',
    color: 'success'
  });
  var link = document.createElement('a');
  var panelName = response.data.panelName;
  link.href = (0, _createCsv.createPanelDetailsCsv)(response.data);
  link.download = !(0, _isNil.default)(panelName) ? "".concat(panelName) : 'panel.csv';
  link.click();
}

function getPanelDetailsFailure(context) {
  context.executeAction(_showNotification.default, {
    heading: 'Panel Download Unsuccessful',
    message: 'We are unable to download the panel',
    color: 'danger'
  });
}

var _default = function _default(config) {
  return {
    serviceName: 'missingPersons.getPanelDetails',
    authenticate: true,
    config: {
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.missingPersons.getPanelDetails')
    },
    onSuccess: getPanelDetailsSuccess,
    onError: getPanelDetailsFailure,
    onFailed: getPanelDetailsFailure,
    onTimeout: getPanelDetailsFailure,
    onFatal: getPanelDetailsFailure
  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(getPanelDetailsSuccess, "getPanelDetailsSuccess", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-panel-details/getPanelDetails.js");
  reactHotLoader.register(getPanelDetailsFailure, "getPanelDetailsFailure", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-panel-details/getPanelDetails.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-panel-details/getPanelDetails.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();