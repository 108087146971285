"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _showNotification = _interopRequireDefault(require("../../../actions/show-notification"));

var _createCsv = _interopRequireDefault(require("../../../common/util/create-csv"));

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

function getReportSuccess(context, _ref) {
  var response = _ref.response;
  context.executeAction(_showNotification.default, {
    heading: 'Report Downloaded Successfully',
    color: 'success'
  });
  var link = document.createElement('a');
  link.href = (0, _createCsv.default)(response.data);
  link.download = 'report.csv';
  link.click();
}

function getReportFailure(context) {
  context.executeAction(_showNotification.default, {
    heading: 'Report Download Unsuccessful',
    message: 'We are unable to download the report',
    color: 'danger'
  });
}

var _default = function _default(config) {
  return {
    serviceName: 'missingPersons.getReport',
    authenticate: true,
    config: {
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.missingPersons.getReport')
    },
    // beforeRequest: beforeUploadPanel,
    onSuccess: getReportSuccess,
    onError: getReportFailure,
    onFailed: getReportFailure,
    onTimeout: getReportFailure,
    onFatal: getReportFailure
  };
};

var _default2 = _default;
exports.default = _default2;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(getReportSuccess, "getReportSuccess", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-report/getReport.js");
  reactHotLoader.register(getReportFailure, "getReportFailure", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-report/getReport.js");
  reactHotLoader.register(_default, "default", "/builds/pulse-platform/pulse-missingperson-web-application/src/services/service-configs/get-report/getReport.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();